import Api from '@/Services/Api'

export default {
  async getAuthSettings() {
    let response = await Api().get('/double-authentification')
    return response.data
  },
  async getAuthSettingsLite() {
    let response = await Api().get('/double-authentification/lite')
    return response.data
  },
  async getAuthSettingsById(id) {
    let response = await Api().get('/double-authentification/' + id)
    return response.data
  },
  async savetAuthSettings(data) {
    let response = await Api().post('/double-authentification', { double_authentification: data })
    return response.data
  },
  async editAuthSettings(data) {
    let response = await Api().put('/double-authentification', data)
    return response.data
  },
  async removeAuthSettings(id) {
    let response = await Api().delete('/double-authentification/' + id)
    return response.data
  },
  async generateVerificationCode(data) {
    let response = await Api().post(`/double-authentification/generate-verification-code/${data.id}`, data)
    return response.data
  },
  async verifyVerificationCode(data) {
    let response = await Api().post('/double-authentification/verify', data)
    return response.data
  }
}
