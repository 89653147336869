<template>
  <div class="login-form d-flex flex-column align-center">
    <v-spacer></v-spacer>
    <img class="login-logo" :src="require('@/Assets/Images/Logo/logo-planete-online.svg')" alt="logo">

    <v-spacer :class="['xs', 'sm'].indexOf(breakpoints) != -1 ? 'my-0' : 'my-8'"></v-spacer>

    <template v-if="listFound">
      <div class="text-justify pa-4" :class="['xs', 'sm'].indexOf(breakpoints) != -1 ? 'mt-4' : 'mt-n6'"
        :style="{ color: getTextColor(listFound.color) }">
        <v-icon left :color="getTextColor(listFound.color)" v-if="listFound.icon">{{ listFound.icon }}</v-icon>
        {{ listFound.message }}
      </div>
    </template>
    <div v-if="!multipleAccountsSSO" class="d-flex flex-column align-stretch">
      <v-form v-if="visibleLogin" v-model="valid" ref="loginForm" lazy-validation class="d-flex flex-column align-stretch"
        @keypress.enter.stop="login">
        <v-text-field :rules="ValidationRules.login" v-model="form.username" :disabled="loading" solo rounded
          :placeholder="$t('home.login.username')" dense class="mt-8" name="username" aria-autocomplete="username"
          autocomplete="username"></v-text-field>
        <v-text-field :rules="ValidationRules.required" v-model="form.password" :disabled="loading"
          :type="showPassword ? 'text' : 'password'" solo rounded :placeholder="$t('home.login.password')" dense
          class="mt-4" name="current-password" aria-autocomplete="current-password" autocomplete="current-password">
          <template v-slot:append>
            <v-btn class="align-self-center" icon @click="showPassword = !showPassword">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-checkbox solo class="stay-logged-in align-self-start" color="primary" v-model="form.stayLogged" dense
          :ripple="false">
          <slot slot="label">
            <span class="stay-logged-in-label">
              {{ $t("home.login.stayLogged") }}
            </span>
          </slot>
        </v-checkbox>

        <v-btn v-if="preferences.activeLogin" @click.prevent="openVerificationPopup" type="submit" :loading="loading"
          :disabled="loading" color="primary" rounded :class="{ 'mt-4': ['xs', 'sm'].indexOf(breakpoints) === -1 }">{{
            $t("home.login.login") }}</v-btn>

        <login-sso></login-sso>

      </v-form>
      <v-form v-else v-model="valid" ref="loginForm" lazy-validation class="d-flex flex-column align-stretch"
        @keypress.enter.stop="login">
        <login-sso></login-sso>
      </v-form>
    </div>
    <!-- sinon afficher loginsso -->
    <div v-else class="d-flex flex-column align-stretch" :class="['xs', 'sm'].indexOf(breakpoints) != -1 ? 'mt-4' : 'mt-n6'">
      <login-sso></login-sso>
    </div>

    <router-link v-if="visibleLogin && !multipleAccountsSSO" :to="{ name: 'ForgottenPassword' }"
      class="reset-password-link mt-2 light-blue--text text-decoration-none">
      {{ $t('home.login.forgottenPassword') }}
    </router-link>

    <!-- Code de vérification Popup -->
    <v-dialog v-model="verificationDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          {{$t('doubleAuth.enter-verification-code')}}
        </v-card-title>
        <v-card-text>
          <v-text-field 
          v-model="verificationCode" 
          v-on:keyup.enter="verifyCode"
          autofocus 
          :label="$t('doubleAuth.verification-code')"
          >
        </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            @click.prevent="verifyCode"
            color="primary"
            :disabled="verificationCode.length < 5"
          >
            {{$t('doubleAuth.confirm')}}
          </v-btn>
          <v-btn @click="closeVerificationPopup" color="grey">
            {{$t('doubleAuth.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MixinValidation from "@/Mixins/ValidationRules";
import LoginService from '@/Services/LoginService';
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import { SVSHomepage } from "@/Services/SupportVisionService";
import AuthSettings from "@/Services/DoubleAuthentification.js"
import Store from "@/Store";
import ColorUtils from "@/Utils/Colors";
import CryptoJS from "crypto-js";
import moment from "moment";
import Vuex from "vuex";

export default {
  name: "LoginForm",
  mixins: [MixinValidation],
  components: {
    LoginSso: () => import("@/Components/Views/Login/LoginSSO")
  },
  props: {
    homepageMessage: Object
  },
  computed: {
    ...Vuex.mapState(["preferences", "authenticationConfiguration", "doubleAuthentification", "informations", "accountsSSO"]),
    ...Vuex.mapMutations(["setDoubleAuthentification"]),
    breakpoints() {
      return this.$vuetify.breakpoint.name
    },
    multipleAccountsSSO() {
      return this.accountsSSO !== undefined && this.accountsSSO.length > 0
    },
    listFound() {
      return this.homepageMessage || this.messageLists[0] || null
    },
    visibleLogin() {
      if (this.authenticationConfiguration !== null) {
        if (this.authenticationConfiguration !== undefined && this.authenticationConfiguration.onlySso !== undefined && this.authenticationConfiguration.onlySso === true) {
          this.$store.commit("toggleLogin", false);
          return false;
        }
        else {
          this.$store.commit("toggleLogin", true);
          return true;
        }
      }
      else {
        this.$store.commit("toggleLogin", false);
        return false;
      }
    }

  },
  data() {
    return {
      valid: false,
      form: {
        username: "",
        password: "",
        stayLogged: false
      },
      loading: false,
      showPassword: false,
      dns: "",
      messageLists: [],
      verificationDialog: false,
      verificationCode: "",
      config: null,
      configUserMail: "",
      authCode: "",
      twoFactorAuthType: "",
      IdHost: "",
      userInfo: {},
      hostLocation: '',
      idsHotesArray: [],
    }
  },
  methods: {
    async openVerificationPopup() {
      if (!this.doubleAuthentification || this.doubleAuthentification.length === 0) {
        this.login();
      }
      else {
        this.idsHotesArray.push({ id: this.doubleAuthentification[0]._id, dataBases: this.doubleAuthentification[0].double_authentification.data.bases });
        this.IdHost = this.doubleAuthentification[0]._id;
        // recuperer les informations de l'utilisateur
        let configUser = {}
        configUser.user = { username: this.form.username, password: this.form.password.toString() }
        configUser.host = this.hostLocation;
        this.userInfo = await AuthSettings.generateVerificationCode({ id: this.IdHost, configUser });
        // verifier si la double authentification est active et quel type de double authentification est configuré
        if (this.userInfo.isActivated === false || this.userInfo.message === "superAdministrator") {
          this.login();
        }
        else if (this.userInfo.message === "invalidAccount") {
          this.$nError(this.$t("doubleAuth.Invalid-username-password"));
        }
        else if (this.userInfo.message === "Aucun email ou numéro de téléphone renseigné, contactez l'administrateur" && this.userInfo.success === false) {
          this.$nError(this.$t("doubleAuth.No-email-phone"));
        }
        else if (this.userInfo.typeTwoFactorAuth === "Cascade") {
          if (this.userInfo.email !== "") {
          this.verificationDialog = true
            this.$nInfo(this.$t("doubleAuth.Sent-by-email"));
          }
          else if (this.userInfo.userPhone !== "") {
            this.verificationDialog = true 
            this.$nInfo(this.$t("doubleAuth.Sent-by-phone"));
          }
          else {
            this.$nError(this.$t("No-email-phone-number-contact-administrator"));
          }
        }
        else if (this.userInfo.typeTwoFactorAuth === "Email") {
          if (this.userInfo.email !== "") {
            this.verificationDialog = true
            this.$nInfo(this.$t("doubleAuth.Sent-by-email"));
          }
          else { 
            this.$nError(this.$t("doubleAuth.No-email-contact-administrator"));
          }
        }
        else if (this.userInfo.typeTwoFactorAuth === "SMS") {
          if (this.userInfo.userPhone !== "") {
            this.verificationDialog = true
            this.$nInfo(this.$t("doubleAuth.Sent-by-phone"));
          }
          else {
            this.$nError(this.$t("doubleAuth.No-phone-number-contact-administrator"));
          }
        }
      }
    },
    // Fermer la popup de vérification
    closeVerificationPopup() {
      this.verificationDialog = false;
      this.verificationCode = ""; // Effacer le champ de code de vérification
    },
    // Vérifier le code de vérification
    async verifyCode() {
      const trimmedVerificationCode = this.verificationCode.trim();

      let res = await AuthSettings.verifyVerificationCode({ id: this.userInfo, verificationCode: trimmedVerificationCode });
      if (res.expired) {
        this.$nError(res.message);
      } else if (!res.success) {
        this.$nError(res.message);
      } else {
        this.login();
      }
    },
    getTextColor(colorName) {
      return ColorUtils.getTextColor(colorName, this.$vuetify.theme.themes.light)
    },
    login: async function () {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        let startScript = performance.now()
        try {
          const locale = this.$i18n.locale
          let response = await LoginService.login(this.form.username, this.form.password.toString(), locale)


          let endScript = performance.now()
          let time = endScript - startScript;
          if (response != undefined) {
            if (response.isSso) {
              await LoginService.loginSSO()
            }
            else {
              if (response.authToken) {
                sessionStorage.setItem("AUTH_TOKEN", response.authToken)
              }

              if (response.user != undefined) {

                // Délai de connexion pour la confiance utilisateur
                if (time >= 1500) time = 1499
                await this.sleep(1500 - time)
                this.$nSuccess(this.$t("app.login.success"))

                this.changeLang(response.user.defaultLanguage)
                const user = response.user
                Store.commit("setUser", { user: user })

                let responseGetInterface = await PlaneteOnlineService.getInterface()
                await this.$store.dispatch("resetStore", this.$_.pick(responseGetInterface, ["widgetBoard", "preferences"]))
                if (this.preferences.soundOn == undefined) {
                  this.$store.commit("toggleSound", false)
                }
                this.$uiSounds.state.preferences.soundOn = this.preferences.soundOn

                if (this.form.stayLogged) { // Si l'utilisateur veut rester connecté, on génère un token et on le met dans local storage
                  let token = this.$uuid.v4()

                  this.$store.commit("stayLogged", { value: token })
                  localStorage.setItem(CryptoJS.MD5("userToken"), token)
                } else { // Si l'utilisateur ne veut pas rester connecté, on vérifie qu'il n'existe pas de token et on le supprime le cas échéant
                  if (localStorage.getItem(CryptoJS.MD5("userToken"))) {
                    localStorage.removeItem(CryptoJS.MD5("userToken"))
                  }
                }

                let store = this.$_.pick(this.$store.state, ["widgetBoard", "informations", "notifications", "preferences"])
                try {
                  let responseInterface = await PlaneteOnlineService.postInterface(store)
                } catch (e) {
                  console.error(e)
                }

                await this.sleep(1500 - time)

                this.loading = false
                let nextRouteParams = { name: 'Home' }
                if (response.isNewPasswordRequired) {
                  nextRouteParams.query = { changePassword: true }
                }
                await this.$router.push(nextRouteParams)

              } else {

                // Délai pour la confiance utilisateur
                if (time >= 1000) time = 999
                await this.sleep(1000 - time)
                this.loading = false;
                this.$nError(this.$t("app.login.responses." + response, "", 5000) || this.$t("app.login.noResponse"))
              }
            }
          } else {
            this.loading = false;
            this.$nError(this.$t("app.login.responses.unavailable", "", 5000))
          }
        } catch (e) {
          this.$nError(this.$t("app.login.responses.unavailable", "", 5000))
        }
        this.loading = false

      }
    },
  },
  async mounted() {
    let config = await AuthSettings.getAuthSettingsLite()
    this.$store.commit("setDoubleAuthentification", config);
    this.hostLocation = await this.getDns();


    this.dns = this.getDns()
    let messageLists = await SVSHomepage.getHomepageMessageList()
    messageLists = messageLists.filter(a => a.active)
    messageLists = messageLists.filter(message => {
      if (message.start == null && message.end == null)
        return true

      if (message.end == null)
        return moment(message.start, "YYYY-MM-DD") <= moment()

      if (message.start == null)
        return moment(message.end, "YYYY-MM-DD") >= moment()

      return moment(message.start, "YYYY-MM-DD") <= moment()
        && moment(message.end, "YYYY-MM-DD") >= moment().startOf("day")
    })


    for (let list of messageLists) {
      let baseList = list.list.map(b => b.name)
      if (baseList.indexOf(this.dns) !== -1) {
        this.messageLists.push(list)
      }
    }
  }
}
</script>

<style scoped>
.login-form {
  /*position: relative;*/
  /*top: 50%;*/
  /*transform: translateY(-50%);*/
  width: 300px;
  margin: 0 auto;
}

.login-logo {
  width: auto;
  height: 80px;
}

@media (max-width: 325px) {
  .login-logo {
    max-width: 100%;
  }
}

@media (max-width: 599px) {
  .login-form {
    width: 75%;
  }

  .login-logo {
    margin-top: 1em;
    height: 40px;
  }
}

@media (min-width: 599px) and (max-width: 959px) {
  .login-form {
    width: 100%;
  }

  .login-logo {
    margin-top: 1.5em;
    height: 48px;
  }
}

@media (min-width: 959px) and (max-width: 1263px) {
  .login-logo {
    margin-top: 1.5em;
    height: 64px;
  }
}

.stay-logged-in {
  position: relative;
  top: -1.4em;
  /*font-size: .5em;*/
}

.stay-logged-in>div>div>div i {
  font-size: 3em !important;
}

.stay-logged-in-label {
  font-size: .8em;
}

/*.stay-logged-in label {*/
/*  font-size: 2em !important;*/
/*}*/

.reset-password-link {
  font-size: .8em;
}

.reset-password-link:hover {
  text-shadow: 0 0 1px rgba(0, 0, 0, .2);
}
</style>